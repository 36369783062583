import React from 'react';
import { BoxContainer } from 'layout/box-container';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { TextInput } from 'core/text-input';
import { RoundButton } from 'core/round-button';
import { useFormik } from 'formik';
import { SubscribeFormSchema } from 'modules/shop/home/sections/subscribe-form.schema';
import { Icon } from 'core/icon';

export interface SubscribeSectionProps {
  subscribe: (email: string) => void;
  titleTypography?: JSX.Element;
}

export const SubscribeSection = ({
  subscribe,
  titleTypography,
}: SubscribeSectionProps) => {
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: SubscribeFormSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: ({ email }) => {
      subscribe(email);
    },
  });

  return (
    <StackedContainer
      alignItems="center"
      height="100%"
      paddingTop={{ lg: 's6', md: 's5' }}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <StackedContainer padding="unset" gap="s2" justifyItems="center">
          <Icon name="email-round" height="7.6rem" color="black" />

          {titleTypography || (
            <Typography variant="h5" tx="home:list.title" fontSize="1.5rem" />
          )}

          <Typography
            tx="home:list.description"
            color="grey"
            align={{ md: 'center' }}
            maxWidth={{ lg: 'unset', sm: '29rem' }}
          />
          <BoxContainer
            marginTop="s2"
            width={{ lg: '200%', md: '100%', sm: 'unset' }}
            maxWidth="34rem">
            <TextInput
              name="email"
              placeholder="Your Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              icon={
                <RoundButton
                  color="primary"
                  icon={<Icon name="right-arrow" color="white" size="1.6rem" />}
                  disabled={!formik.isValid}
                />
              }
            />
          </BoxContainer>
        </StackedContainer>
      </form>
    </StackedContainer>
  );
};
