import React from 'react';
import { ImageContainer } from 'layout/image-container';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { BoxContainer } from 'layout/box-container';
import { SizedContainer } from 'layout/sized-container';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Icon } from 'core/icon';
import { Badge } from 'core/badge';

const renderContent = (hidden: ResolutionAwareProp<boolean> = false) => {
  return (
    <StackedContainer
      alignContent="center"
      justifyItems={{ lg: 'flex-start', md: 'center' }}
      hidden={hidden}
      gap="s2">
      <Badge variant="h5" tx="home:reach.badge" color="white" />

      <ImageContainer
        src="pages/home/logo-reach.png"
        width="11rem"
        height="3.6rem"
        backgroundColor="unset"
      />

      <BoxContainer marginTop="s1" maxWidth="44rem">
        <Typography
          tx="home:reach.name"
          color="white"
          variant="p1"
          textStyle="bold"
          align={{ lg: 'left', md: 'center' }}
        />

        <Typography
          tx="home:reach.description"
          color="translucentWhite"
          variant="p1"
          align={{ lg: 'left', md: 'center' }}
        />
      </BoxContainer>

      <BoxContainer marginTop={{ lg: 's2', md: 's1' }}>
        <Button
          tx="home:reach.discover"
          textStyle="regular"
          variant="action"
          color="white"
          icon={
            <Icon
              name="arrow-right"
              color="white"
              width="1.9rem"
              height="1.5rem"
            />
          }
          textVariant="p1"
          iconLocation="right"
          linkHref="/reach"
        />
      </BoxContainer>
    </StackedContainer>
  );
};

export const ReachSection = () => {
  return (
    <BoxContainer
      horizontalMargin={{ lg: 's3', sm: 's1' }}
      marginTop={{ lg: 's3', md: 's1' }}>
      <ImageContainer
        src={{
          lg: 'pages/home/reach.jpg',
          sm: 'pages/home/reach-small.jpg',
        }}
        backgroundColor="lightGrey"
        aspectRatio={{ lg: '1:0.50', md: '1:1' }}
        alt="Shop Reach table light by Dims.">
        <Grid columns="6fr 5fr" alignItems="center" height="100%">
          <Cell column={2}>{renderContent({ lg: false, md: true })}</Cell>
        </Grid>
      </ImageContainer>
      <SizedContainer
        backgroundColor="opaqueGreen"
        aspectRatio="1:1"
        hidden={{ lg: true, md: false }}>
        {renderContent()}
      </SizedContainer>
    </BoxContainer>
  );
};
