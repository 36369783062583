import React from 'react';
import { Grid } from 'layout/grid';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { StackedContainer } from 'layout/stacked-container';

const items = ['story', 'designers', 'materials'];

const routes = {
  story: '/about',
  designers: '/designers',
  materials: '/materials',
};

const DesignCard = ({ item }) => {
  return (
    <div>
      <ImageContainer src={`pages/home/square-${item}.jpg`} aspectRatio="1:1" />

      <StackedContainer
        padding="unset"
        gap="s1"
        justifyItems={{ lg: 'center', md: 'left' }}
        paddingTop={{ lg: 's2', md: 's1' }}>
        <Button
          tx={`home:designerLights.items.${item}.title`}
          variant="action"
          icon={
            <Icon
              name="arrow-right"
              color="black"
              width="1.9rem"
              height="1.6rem"
            />
          }
          iconLocation="right"
          color="black"
          textVariant="h3"
          linkHref={routes[item]}
        />

        <Typography
          tx={`home:designerLights.items.${item}.description`}
          color="grey"
          align={{ lg: 'center', md: 'left' }}
        />
      </StackedContainer>
    </div>
  );
};

export const DesignerAwardsSection = () => {
  return (
    <StackedContainer
      verticalPadding={{ lg: 's6', md: 's5' }}
      backgroundColor="paper"
      gap={{ lg: 's5', md: 's4' }}>
      <StackedContainer
        padding="unset"
        justifyItems="center"
        gap={{ lg: 's4', md: 's3' }}>
        <Typography
          tx="home:designerLights.title"
          variant="h2"
          align="center"
          maxWidth={{ lg: 'unset', md: '30rem', sm: '28rem' }}
        />
        <Typography
          tx="home:designerLights.description"
          variant="p1"
          align="center"
          color="grey"
          maxWidth="91rem"
        />
      </StackedContainer>

      <Grid
        columns={{ lg: 3, md: 1 }}
        verticalPadding="unset"
        horizontalPadding="8.33%"
        gap={{ lg: 's5', md: 's4' }}>
        {items.map(item => (
          <DesignCard key={item} item={item} />
        ))}
      </Grid>
    </StackedContainer>
  );
};
