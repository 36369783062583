import React from 'react';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { Spacer } from 'layout/spacer';

export const ShippingSection = () => {
  return (
    <StackedContainer
      verticalPadding={{ lg: 's5', md: 's4' }}
      justifyItems="center">
      <Icon
        name="free-shipping"
        size={{ lg: '12rem', sm: '9rem' }}
        color="primary"
      />
      <Spacer height="s1" />
      <Typography tx="home:shipping.title" variant="h3" align="center" />
      <Typography
        tx="home:shipping.description"
        color="grey"
        align="center"
        maxWidth="56rem"
      />
    </StackedContainer>
  );
};
