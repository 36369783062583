import styled, { css } from 'styled-components';
import Img from 'react-image';
import { animated } from 'react-spring';
import { ImageContainer } from 'layout/image-container';
import { media } from 'styles/media';

export const StyledPressImage = styled(Img)`
  cursor: pointer;
  padding-bottom: 1rem;
`;

export const StyledHeading = styled.h1`
  ${media.lessThan(`md`)`
    text-align: center;
  `}
`;

export const StyledPressImageIndicator = styled(animated.div)`
  ${({ theme }) => css`
    height: 1px;
    width: auto;
    background-color: ${theme.colors.black};
    opacity: 0.5;
  `}
`;

export const StyledInspiredOverlayButtonContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing.s1};
    left: ${theme.spacing.s1};
    opacity: 0;
    transition: ${theme.transitions.base};

    ${media.lessThan('md')`
      display: none;
    `}
  `}
`;

export const StyledInspiredImage = styled(ImageContainer)`
  &:hover ${StyledInspiredOverlayButtonContainer} {
    opacity: 1;
  }
`;
