import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { SizedContainer } from 'layout/sized-container';
import { StyledHeading } from 'modules/shop/home/home.styles';

export const HeroSection = () => (
  <Grid
    padding="unset"
    columns={{ lg: 2, md: 1 }}
    gap="unset"
    height={{
      lg: `calc(100vh - 18rem)`,
      md: 'unset',
    }}>
    <Cell row={{ lg: 1, md: 2 }}>
      <SizedContainer
        backgroundColor="#493f3f"
        aspectRatio={{ lg: 'unset', md: '1:1' }}>
        <StackedContainer
          padding="unset"
          gap={{ lg: 's4', md: 's2', sm: 's1' }}
          justifySelf="center"
          justifyItems={{ lg: 'unset', md: 'center' }}
          alignContent="center"
          horizontalPadding={{ lg: '15%', md: 's3', sm: 's1' }}>
          <StyledHeading>
            <Typography
              htmlText="Light.&nbsp;"
              variant="h1"
              type="span"
              color="white"
              align={{ lg: 'unset', md: 'center' }}
              display="inline-block"
            />
            <Typography
              text={'Like no other.'}
              variant="h1"
              type="span"
              color="white"
              align={{ lg: 'unset', md: 'center' }}
              display="inline-block"
            />
          </StyledHeading>

          <Typography
            tx="home:hero.description"
            variant="p1"
            color="white"
            maxWidth={{ lg: '45rem' }}
            align={{ lg: 'unset', md: 'center' }}
            marginBottom={{ lg: 'unset', md: 's2' }}
            justifySelf={{ lg: 'unset', md: 'center' }}
          />

          <Button
            tx="home:hero.shopLink"
            color="white"
            width="17rem"
            linkHref="/designers"
            justifySelf={{ lg: 'unset', md: 'center' }}
          />
        </StackedContainer>
      </SizedContainer>
    </Cell>

    <Cell>
      <ImageContainer
        aspectRatio={{ lg: null, md: '1:1' }}
        objectPosition="50% 0"
        src={{
          lg: 'pages/home/hero.jpg',
          sm: 'pages/home/hero-small.jpg',
        }}
      />
    </Cell>
  </Grid>
);
