import React from 'react';
import { PostCardProps } from 'modules/blog/home/sections/post-card/post-card.props';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { formatDate } from 'utils/date';
import { StyledPostCardDateTypography } from 'modules/blog/home/sections/post-card/post-card.styles';
import Link from 'next/link';
import { PostCardPresets } from 'modules/blog/home/sections/post-card/post-card.presets';
import { BoxContainer } from 'layout/box-container';
import { StackedContainer } from 'layout/stacked-container';
import { Tag } from '@tryghost/content-api';
import { Badge } from 'core/badge';

export const PostCard = (props: PostCardProps) => {
  const {
    post,
    imageAspectRatio,
    showDescription,
    showDate,
    showTags,
    infoHorizontalPadding,
  } = Object.assign({}, PostCardPresets, props);

  if (!post) {
    return null;
  }

  return (
    <Link href="/blog/[slug]" as={`/blog/${post.slug}`}>
      <a draggable="false">
        <div>
          <ImageContainer
            aspectRatio={imageAspectRatio}
            src={post.feature_image}
            source="external"
          />

          <BoxContainer horizontalPadding={infoHorizontalPadding}>
            {showDate && (
              <StyledPostCardDateTypography
                text={formatDate(post.published_at, 'MMM DD, YYYY')}
                color="grey"
              />
            )}

            {showTags && !!post.tags.length && (
              <StackedContainer
                padding="unset"
                flow="column"
                justifyContent="start"
                marginTop="s2"
                marginBottom="s1">
                {post.tags.map((tag: Tag) => (
                  <Badge key={tag.id} text={tag.name} color="grey" />
                ))}
              </StackedContainer>
            )}

            <Typography variant="h3" text={post.title} />

            {showDescription && (
              <Typography
                variant="h4"
                text={post.description}
                color="grey"
                textStyle="regular"
              />
            )}
          </BoxContainer>
        </div>
      </a>
    </Link>
  );
};
