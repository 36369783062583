import React, { useState } from 'react';
import { Tabs } from 'core/tabs';
import { TabConfig } from 'core/tabs/tabs.props';
import { StackedContainer } from 'layout/stacked-container';
import { Grid } from 'layout/grid';
import range from 'lodash/range';
import { Typography } from 'core/typography';
import { BoxContainer } from 'layout/box-container';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Thumbnail } from 'core/thumbnail';
import InstagramImage1 from 'assets/images/pages/home/quote-bychloewen.png';
import InstagramImage2 from 'assets/images/pages/home/quote-thedrk_knight.png';
import InstagramImage3 from 'assets/images/pages/home/quote-mybelonging.png';
import InstagramImage4 from 'assets/images/pages/home/quote-philcohen.png';
import Link from 'next/link';
import {
  StyledInspiredImage,
  StyledInspiredOverlayButtonContainer,
} from 'modules/shop/home/home.styles';
import { Spacer } from 'layout/spacer';

const tabs = ['living', 'working', 'resting', 'playing'];
const items = range(0, 4);

const data = {
  living: {
    quote:
      '“Since we got our Gantri lights our room has become such a peaceful and relaxing space!”',
    designerInstagram: 'bychloewen',
    designerPhoto: InstagramImage1,
    backgroundColor: '#2a5746',
    products: [
      {
        skuName: 'PyraSphere Table Light',
        skuUrl:
          '/products/10048/pyrasphere-table-light-by-louis-filosa/10048-sm-forest',
        instagram: 'hali.mason',
      },
      {
        skuName: 'Gio Table Light',
        skuUrl: '/products/10062/gio-task-light-by-ammunition/10062-md-stone',
        instagram: 'alice_gao',
      },
      {
        skuName: 'Signal Floor Light',
        skuUrl:
          '/products/10069/signal-floor-light-by-ammunition/10069-md-stone',
        instagram: 'heydavina',
      },
      {
        skuName: 'Word Table Light',
        skuUrl: '/products/10058/word-table-light-by-dims./10058-sm-snow',
        instagram: 'thismintymoment',
      },
    ],
  },
  working: {
    quote:
      '“I tend to always have low light in my place so the warm glow from the light creates the perfect mood to study, or work on photo projects.”',
    designerInstagram: 'thedrk_knight',
    designerPhoto: InstagramImage2,
    backgroundColor: '#60131d',
    products: [
      {
        skuName: 'Buddy Table Light',
        skuUrl:
          '/products/10053/buddy-table-light-by-mona-sharma/10053-sm-snow',
        instagram: 'thedrk_knight',
      },
      {
        skuName: 'Clara Vertigo Table Light',
        skuUrl:
          '/products/10016/clara-vertigo-table-light-by-lorenzo-cartasegna/10016-sm-sand',
        instagram: 'theyoonstudio',
      },
      {
        skuName: 'Gio Task Light',
        skuUrl: '/products/10062/gio-task-light-by-ammunition/10062-md-stone',
        instagram: 'joethommas',
      },
      {
        skuName: 'Suyo Table Light',
        skuUrl:
          '/products/10051/suyo-table-light-by-mckay-nilson/10051-lg-coral',
        instagram: 'sundayhours',
      },
    ],
  },
  resting: {
    quote:
      '“It provides the perfect amount of light as I unwind for bed in the evenings.”',
    designerInstagram: 'mybelonging',
    designerPhoto: InstagramImage3,
    backgroundColor: '#243a68',
    products: [
      {
        skuName: 'Lago Table Light',
        skuUrl:
          '/products/10018/lago-table-light-by-heitor-lobo-campos/10018-md-sand',
        instagram: 'stylefitfatty',
      },
      {
        skuName: 'Weight Table Light',
        skuUrl:
          '/products/10045/weight-table-light-by-sam-gwilt/10045-cm-midnight',
        instagram: 'nordicentrance',
      },
      {
        skuName: 'Dulce Table Light',
        skuUrl:
          '/products/10020/dulce-table-light-by-filippo-mambretti/10020-md-sand',
        instagram: 'bychloewen',
      },
      {
        skuName: 'Gio Wall Light',
        skuUrl: '/products/10065/gio-wall-light-by-ammunition/10065-sm-forest',
        instagram: 'thismodernstyle',
      },
    ],
  },
  playing: {
    quote:
      '“The light delivers a soft, warm glow that provides a welcoming and peaceful atmosphere around our home.”',
    designerInstagram: 'philcohen_',
    designerPhoto: InstagramImage4,
    backgroundColor: '#aa672d',
    products: [
      {
        skuName: 'Paris Table Light',
        skuUrl:
          '/products/10033/paris-table-light-by-javier-martinez/10033-md-midnight',
        instagram: 'raisingfivekind',
      },
      {
        skuName: 'Gio Floor Light',
        skuUrl: '/products/10068/gio-floor-light-by-ammunition/10068-md-carbon',
        instagram: 'philcohen_',
      },
      {
        skuName: 'Maskor Task Light',
        skuUrl:
          '/products/10036/maskor-table-light-by-muka-design-lab/10036-sm-forest',
        instagram: 'kimmyintx',
      },
      {
        skuName: 'Balloon Table Light',
        skuUrl:
          '/products/10015/balloon-table-light-by-chris-granneberg/10015-sm-fog',
        instagram: 'raisingfivekind',
      },
    ],
  },
};

const LightCard = ({ item, position }) => {
  return (
    <div>
      <Link href={data[item].products[position].skuUrl}>
        <a>
          <StyledInspiredImage
            src={`pages/home/${item}-${position + 1}.jpg`}
            aspectRatio="1:1">
            <StyledInspiredOverlayButtonContainer>
              <Button
                text={data[item].products[position].skuName}
                color="white"
                fontSize="1.2rem"
                textStyle="regular"
                height="3.2rem"
                minWidth="auto"
                icon={<Icon name="cart" color="primary" height="2rem" />}
              />
            </StyledInspiredOverlayButtonContainer>
          </StyledInspiredImage>
        </a>
      </Link>

      <BoxContainer
        hidden={{ lg: true, md: false }}
        marginTop="s1"
        marginBottom=".5rem">
        <Button
          variant="action"
          fontSize="1.2rem"
          text={data[item].products[position].skuName}
          color="white"
          linkHref={data[item].products[position].skuUrl}
          textStyle="regular"
          icon={
            <Icon
              name="arrow-right"
              color="white"
              width="1.5rem"
              height="1.2rem"
            />
          }
          iconLocation="right"
        />
      </BoxContainer>

      <Typography
        variant="link"
        fontSize="1.2rem"
        text={`@${data[item].products[position].instagram}`}
        color="lightGrey"
        href={`https://www.instagram.com/${data[item].products[position].instagram}`}
        prefetch={false}
        target="_blank"
      />
    </div>
  );
};

export const GetInspiredSection = () => {
  const [backgroundColor, setBackgroundColor] = useState('#2a5746');

  const onTabSelected = (selectedTab: number) => {
    switch (selectedTab) {
      case 0:
        setBackgroundColor(data.living.backgroundColor);
        break;
      case 1:
        setBackgroundColor(data.working.backgroundColor);
        break;
      case 2:
        setBackgroundColor(data.resting.backgroundColor);
        break;
      case 3:
        setBackgroundColor(data.playing.backgroundColor);
        break;
    }
  };

  const tabsConfig: TabConfig[] = tabs.map((tab: string) => ({
    heading: tab,
    content: (
      <div>
        <Grid columns={{ lg: 4, md: 2 }} padding="unset" rowGap="s3">
          {items.map(item => (
            <LightCard key={item} item={tab} position={item} />
          ))}
        </Grid>

        <StackedContainer
          padding="unset"
          paddingTop={{ lg: 's5', md: 's4' }}
          justifyContent="center"
          justifyItems="center"
          gap="s2">
          <Typography
            text={data[tab].quote}
            variant="h3"
            textStyle="regular"
            color="white"
            align="center"
            maxWidth="91rem"
          />

          <Link
            href={`https://www.instagram.com/${data[tab].designerInstagram}`}
            prefetch={false}>
            <a target="_blank">
              <TypographyWithIcon
                text={`@${data[tab].designerInstagram}`}
                textStyle="regular"
                color="lightGrey"
                icon={
                  <Thumbnail
                    src={data[tab].designerPhoto}
                    border="unset"
                    size="4rem"
                  />
                }
              />
            </a>
          </Link>
        </StackedContainer>
      </div>
    ),
  }));

  return (
    <StackedContainer
      backgroundColor={backgroundColor}
      verticalPadding={{ lg: 's6', md: 's5' }}
      gap={{ lg: 's5', md: 's4' }}>
      <StackedContainer
        padding="unset"
        rowGap="s1"
        justifyItems="center"
        justifyContent="center">
        <Typography
          tx="home:getInspired.title"
          color="white"
          variant="h2"
          align="center"
        />

        <Typography
          tx="home:getInspired.description"
          color="lightGrey"
          variant="p1"
          align="center"
        />

        <Link href="https://www.instagram.com/gantri" prefetch={false}>
          <a target="_blank">
            <Spacer height="s1" />

            <TypographyWithIcon
              variant="p1"
              color="lightGrey"
              align="center"
              text="@gantri"
              icon={<Icon name="instagram" color="lightGrey" size="2.4rem" />}
            />
          </a>
        </Link>
      </StackedContainer>

      <Tabs
        config={tabsConfig}
        headingWidth="7.6rem"
        headingAlign="center"
        headingGap={{ lg: 30, md: 12 }}
        headingActiveColor="white"
        headingColor="translucentWhite"
        headerBorderVisible={false}
        onTabSelected={onTabSelected}
      />
    </StackedContainer>
  );
};
