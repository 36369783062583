import styled from 'styled-components';
import { Typography } from 'core/typography';
import { media } from 'styles/media';

export const StyledPostCardDateTypography = styled(Typography)`
  margin-top: 2rem;
  margin-bottom: 1rem;

  ${media.lessThan('md')`
    margin-top: 1rem;
  `}
`;
