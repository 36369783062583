import React from 'react';
import { ImageContainer } from 'layout/image-container';
import { Grid } from 'layout/grid';
import { Typography } from 'core/typography';
import { Badge } from 'core/badge';
import { Button } from 'core/button';
import { StackedContainer } from 'layout/stacked-container';

export const GiftCardsSection = () => {
  return (
    <ImageContainer
      height={{ lg: '48rem', md: '165vw' }}
      width="100%"
      src={{
        lg: 'pages/home/gift-cards.jpg',
        sm: 'pages/home/gift-cards-small.jpg',
      }}>
      <Grid
        justifyItems={{ lg: 'start', md: 'center' }}
        height="100%"
        rows={3}
        alignContent={{ lg: 'center', md: 'start' }}
        gap="unset"
        paddingLeft={{ lg: '8.33%', md: 's3', sm: 's1' }}
        verticalPadding="s5">
        <Badge tx="home:giftCards.badge" color="white" />

        <StackedContainer
          paddingTop={{ lg: 's2', md: 's1' }}
          paddingBottom={{ lg: 's4', md: 's3' }}
          padding="unset">
          <Typography
            tx="home:giftCards.title"
            variant="h2"
            color="white"
            align={{ lg: 'start', md: 'center' }}
          />
          <Typography
            tx="home:giftCards.description"
            variant="p1"
            color="lightGrey"
            align={{ lg: 'start', md: 'center' }}
          />
        </StackedContainer>

        <Button
          tx="home:giftCards.btn"
          color="white"
          width="15rem"
          linkHref="/gift-cards"
        />
      </Grid>
    </ImageContainer>
  );
};
