import React, { useState } from 'react';
import { Carousel } from 'core/carousel';
import { BoxContainer } from 'layout/box-container';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import {
  StyledPressImage,
  StyledPressImageIndicator,
} from 'modules/shop/home/home.styles';
import { useSprings } from 'react-spring';
import { delay } from 'utils/delay';
import WiredImage from 'assets/images/pages/home/press-wired.png';
import FastCompanyImage from 'assets/images/pages/home/press-fastcompany.png';
import CnetImage from 'assets/images/pages/home/press-cnet.png';
import CurbedImage from 'assets/images/pages/home/press-curbed.png';
import DwellImage from 'assets/images/pages/home/press-dwell.png';
import { QuotesIcon } from 'layout/common';

const quotes = [
  {
    image: WiredImage,
    url: 'https://www.wired.com/story/ammunition-gantri-lighting',
    quote: 'home:press.quotes.wired',
    alt: 'Wired',
  },
  {
    image: FastCompanyImage,
    url:
      'https://www.fastcompany.com/90467941/these-gorgeous-3d-printed-lighting-fixtures-are-the-anti-ikea',
    quote: 'home:press.quotes.fastCompany',
    alt: 'Fast Company',
  },
  {
    image: CnetImage,
    url:
      'https://www.cnet.com/news/these-luxury-led-lamps-are-made-out-of-corn',
    quote: 'home:press.quotes.cnet',
    alt: 'Cnet',
  },
  {
    image: CurbedImage,
    url:
      'https://www.curbed.com/2020/2/26/21153646/gantri-3d-printed-lights-ammunition',
    quote: 'home:press.quotes.curbed',
    alt: 'Curbed',
  },
  {
    image: DwellImage,
    url: 'https://www.dwell.com/article/gantri-ammunition-lighting-dfa8c066',
    quote: 'home:press.quotes.dwell',
    alt: 'Dwell',
  },
];

export const PressSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [springs, setSprings] = useSprings(quotes.length, index => ({
    scale: index === 0 ? 1 : 0,
  }));

  const goToPage = async position => {
    setCurrentIndex(position);

    await delay(100);

    setSprings(index => {
      return {
        scale: index === position ? 1 : 0,
      };
    });
  };

  const renderContent = position => (
    <StackedContainer
      flow="row"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      gap="s2">
      <QuotesIcon />
      <Typography
        tx={quotes[position].quote}
        type="p"
        variant="h3"
        align="center"
        textStyle="regular"
      />
      <BoxContainer hidden={{ lg: true, md: false }} textAlign="center">
        <StyledPressImage
          src={quotes[position].image}
          width="160px"
          height="42px"
          draggable={false}
          alt={quotes[position].alt}
        />
      </BoxContainer>
    </StackedContainer>
  );

  return (
    <BoxContainer
      paddingTop="s5"
      verticalPadding={{ lg: 's6', md: 's5' }}
      horizontalMargin={{ lg: 's3', md: 'unset' }}>
      <Carousel
        currentIndex={currentIndex}
        numberOfPages={5}
        height={{ lg: '17rem', md: '24rem' }}
        controlsHidden={true}
        indicatorsPosition="center"
        indicatorsHidden={{ lg: true, md: false }}
        containerBackgroundColor="white"
        indicatorsColor="translucentBlack"
        displayScaleAnimation={false}
        renderContent={renderContent}
        onPageChanged={goToPage}
      />

      <StackedContainer
        flow="column"
        gap="s5"
        hidden={{ md: true }}
        justifyContent="center">
        {springs.map(({ scale }, idx) => (
          <div key={quotes[idx].url}>
            <StyledPressImage
              src={quotes[idx].image}
              width="160px"
              height="42px"
              onClick={() => goToPage(idx)}
            />
            <StyledPressImageIndicator style={{ scale }} />
          </div>
        ))}
      </StackedContainer>
    </BoxContainer>
  );
};
