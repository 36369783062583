import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { ImageContainer } from 'layout/image-container';
import Link from 'next/link';

const awards = [
  {
    width: '10rem',
    height: '12.5rem',
    image: 'pages/home/award-fastcompany.jpg',
    link:
      'https://www.fastcompany.com/90547927/general-excellence-innovation-by-design-2020',
  },
  {
    width: '9rem',
    height: '12.5rem',
    image: 'pages/home/award-time.jpg',
    link:
      'https://time.com/collection/best-inventions-2020/5911348/ammunition-gantri-3d-printed-lights',
  },
  {
    width: '18rem',
    height: '12.5rem',
    image: 'pages/home/award-gearpatrol.jpg',
    link:
      'https://www.gearpatrol.com/briefings/a34671204/the-100-best-products-of-2020',
  },
  {
    width: '10rem',
    height: '12.5rem',
    image: 'pages/home/award-cleverest.jpg',
    link:
      'https://www.architecturaldigest.com/story/the-cleverest-awards-2020-50-products-that-work-harder-in-your-home',
  },
];

export const AwardsSection = () => {
  return (
    <StackedContainer
      padding="unset"
      columns={{ lg: 'repeat(4, max-content)', md: '1fr' }}
      gap={{ lg: 's6', md: 's4' }}
      justifyContent="center"
      justifyItems="center"
      verticalPadding="s5">
      {awards.map(award => (
        <Link href={award.link} prefetch={false} key={award.image}>
          <a target="_blank">
            <ImageContainer
              src={award.image}
              backgroundColor="unset"
              height={award.height}
              width={award.width}
              objectFit="contain"
            />
          </a>
        </Link>
      ))}
    </StackedContainer>
  );
};
